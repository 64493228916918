import styled from "styled-components";
import { UNI_BACKGROUD_COLOR_VAR_NAME } from "@website-builder/utilities-index";
import {
	COLOR_TEXT_PRIMARY_B2U,
	FONT_WT_HEAVY,
	FONT_WT_LIGHT,
	MOBILE_L_BP,
	COLOR_WHITE,
} from "@website-builder/utilities-index";

export const NotFoundWrapper = styled.main`
	color: ${COLOR_TEXT_PRIMARY_B2U};
	font-weight: ${FONT_WT_LIGHT};
	text-align: center;
	margin-top: 200px;
	display: flex;
	flex-direction: column;
	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		margin-top: 100px;
	}
`;

export const Header = styled.h2`
	margin-top: 50px;
	font-weight: ${FONT_WT_HEAVY};
	font-size: 50px;
	line-height: 63px;
`;

export const Content = styled.div`
	font-size: 18px;
	line-height: 22px;
`;

export const Button = styled.button`
	margin-top: 30px;
	align-self: center;
	${({ rootVar }) => {
		return `background-color: ${
			rootVar
				? rootVar === UNI_BACKGROUD_COLOR_VAR_NAME.DEFAULT
					? `${COLOR_WHITE}`
					: `var(--${rootVar})`
				: `var(--secondary)`
		};`;
	}}
	width: 267px;
	height: 47px;
	border: 0;
	border-radius: 3px;
	.link {
		color: ${COLOR_WHITE};
		font-weight: ${FONT_WT_HEAVY};
		font-size: 18px;
		line-height: 23px;
		text-decoration: none;
	}
`;
