import React from "react";
import { Helmet } from "react-helmet";
import { CtaLink } from "../../../../Elements";
import { CloudinaryImage } from "@website-builder/ui/shared/elements";
import { NotFoundWrapper, Header, Content, Button } from "./styles";
import { Logo } from "./../../../../styles";
import { getLogoOrImageConfig } from "@website-builder/utilities-index";

const NotFound = ({ basicConfig, story }) => {
	const { primaryLogo, secondaryLogo } = basicConfig;
	const {
		content: {
			header,
			subText,
			buttonText,
			buttonBgColor,
			logoConfig = getLogoOrImageConfig({}),
		},
	} = story;
	return (
		<NotFoundWrapper>
			<Logo width={logoConfig.width} height={logoConfig.height}>
				<CloudinaryImage
					className="siteLogo"
					url={logoConfig.type === "primary" ? primaryLogo : secondaryLogo}
					quality="auto:best"
					fetchFormat="auto"
					dpr="auto"
					lazyload={false}
				/>
			</Logo>
			<Header>{header || "Oops!"}</Header>
			<Content>
				{subText || "We can’t find the page you are looking for."}
			</Content>
			<Button rootVar={buttonBgColor}>
				<CtaLink type="external" value="/" target="_self" className="link">
					{buttonText || `Go Back to Homepage`}
				</CtaLink>
			</Button>
		</NotFoundWrapper>
	);
};

export default NotFound;
